<template>
  <Container ref="container"
             class=""
  >
    <div class="relative z-20 flex items-center h-full text-white pointer-events-none">
      <div class="w-full">
        <AnimatedText :copy="title"
                      :visible="textVisible"
                      tag="h2"
                      class="max-w-4xl text-4xl leading-tight md:text-5xl md:leading-tight lg:text-6xl lg:leading-tight"
        />
      </div>
    </div>
    <div class="absolute top-0 md:w-full flex items-end justify-between -mt-[50%] md:-mt-[30%] -mx-10 space-x-6">
      <div v-for="(img, i) in images.slice(0, 3)"
           :key="i"
           :class="[
             { 'w-[180px] lg:w-[284px]': i === 0 },
             { 'w-[214px] lg:w-[275px] mb-20 lg:mt-[15vh]': i === 1 },
             { 'w-[200px] mb-4 hidden md:block': i === 2 },
           ]"
           :style="{ transform: `translateY(${trigger * 25}vh)` }"
      >
        <!-- Wrapper for @tailwindcss/aspect-ratio padding trick -->
        <div class="aspect-w-1 aspect-h-1">
          <NuxtImg :src="img.src"
                   :alt="img.alt || ''"
                   class="object-cover rounded-lg"
                   sizes="xs:214px sm:214px md:214px lg:284px xl:284px xxl:284px"
                   loading="lazy"
          />
        </div>
      </div>
    </div>
    <div class="absolute bottom-0 flex items-start justify-between md:w-full -mb-[50%] md:-mb-[30%] -mx-10 space-x-6">
      <div v-for="(img, i) in images.slice(3, 6)"
           :key="i"
           :class="[
             { 'w-[230px] lg:w-[240px]': i === 0 },
             { ' w-44 mt-20 lg:w-[245px] lg:mt-[10vh]': i === 1 },
             { 'w-[275px]  lg:w-[405px] mt-12 hidden md:block': i === 2 },
           ]"
           :style="{ transform: `translateY(-${trigger * 25}vh)` }"
      >
        <!-- Wrapper for @tailwindcss/aspect-ratio padding trick -->
        <div class="aspect-w-1 aspect-h-1">
          <NuxtImg :src="img.src"
                   :alt="img.alt || ''"
                   class="object-cover rounded-lg"
                   sizes="xs:230px sm:230px md:275px lg:405px xl:405px xxl:405px"
                   loading="lazy"
          />
        </div>
      </div>
    </div>
  </Container>
</template>

<script setup lang="ts">
import { watch, ref } from 'vue'
import AnimatedText from './animated-text.vue'

interface SlidingImageProps {
  trigger: number
  images: {
    src: string
    alt?: string
  }[]
  title: string
}
const props = defineProps<SlidingImageProps>()

const container = ref()
const textVisible = ref(false)

watch(
  () => props.trigger,
  trigger => {
    if (trigger > 1 || trigger < 0) textVisible.value = false
    else textVisible.value = true
  }
)
</script>
